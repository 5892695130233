import React from "react"
import Draggable, { DraggableCore } from "react-draggable" // Both at the same time
// import { Link } from "gatsby"
// import Head from "../components/head/head"
// import Layout from "../components/layout/layout"
// import indexStyling from "./index.module.scss"
// import testemontailImage from "../assets/avatars/avatar1.jpg"
// import {
//   MessageCircle,
//   FastForward,
//   Target,
//   DollarSign,
//   ZoomIn,
//   Edit2,
// } from "react-feather"
// require("typeface-cooper-hewitt")
// require("typeface-inter")
// require("typeface-gidole")
// require("typeface-libre-franklin")
// require("typeface-metropolis")
// require("typeface-cantarell")
// require("typeface-aleo")
// require("typeface-gothic-a1")
// require("typeface-ibm-plex-sans-condensed")
// require("typeface-proza-libre")
// require("typeface-playfair-display")
// require("typeface-poppins")
// require("typeface-roboto")

import "98.css"

//import SEO from "../components/seo/seo"
//import { Link } from "gatsby"
//import ActionButton from "../components/actionbutton/actionbutton"

//import Img from "gatsby-image"
//import P5 from '../components/p5sketch/p5'

const IndexPage = () => (
  <div
    id="root"
    className="flex flex-col items-center justify-center w-screen min-h-screen"
  >
    <Draggable
      // axis="x"
      handle=".handle"
      defaultPosition={{ x: 0, y: 0 }}
      position={null}
      // grid={[25, 25]}
      scale={1}
      // onStart={this.handleStart}
      // onDrag={this.handleDrag}
      // onStop={this.handleStop}
    >
      <div
        style={{ width: 500 }}
        className="w-4/5 window h-3/4 handle"
        // data-sal="fade"
        // data-sal-delay="300"
        // data-sal-easing="ease"
      >
        <div className="title-bar">
          <div className="title-bar-text">Hello Internet</div>
          <div className="title-bar-controls">
            <button aria-label="Minimize" />
            <button aria-label="Maximize" />
            <button aria-label="Close" />
          </div>
        </div>

        <div className="h-full window-body">
          <p style={{ textAlign: "left" }}>Would you like to reach out?</p>
          <div>
            <ul className="tree-view">
              <li>Who, What, How?</li>
              <li>
                <details closed>
                  <summary>Who</summary>
                  <ul>
                    <li>Leo Hösl</li>
                    <li>React Developer</li>
                    <li>Habit and Process Obsessed</li>
                    <li>Book Fanatic</li>
                  </ul>
                </details>
              </li>
              <li>
                <details open>
                  <summary>What</summary>
                  <ul>
                    <li>Blazing Fast Ecommerce</li>
                    <li>Business Goal Oriented</li>
                    <li>Data Driven</li>
                  </ul>
                </details>
              </li>
              <li>
                <details closed>
                  <summary>How</summary>
                  <ul>
                    <li>GatsbyJS / NextJS</li>
                    <li>Headless Shopify / SnipCart</li>
                    <li>Sanity.io / Prismic.io (CMS)</li>
                  </ul>
                </details>
              </li>
              <li>
                Contact
                <details open>
                  <summary>Contact</summary>
                  <ul>
                    <li>leo.hoesl@pm.me</li>
                  </ul>
                </details>
              </li>
            </ul>
          </div>
          {/* <div className="field-row" style={{ justifyContent: "center" }}></div> */}
        </div>

        {/* <ul className="tree-view">
        <li>
          <details open>
            <ul>
              <li>Hi</li>
              <li>Ho</li>
            </ul>
          </details>
        </li>
      </ul> */}
      </div>
    </Draggable>
  </div>
)

export default IndexPage
